// extracted by mini-css-extract-plugin
export var aiChat__preview = "bs_k9";
export var aiChat__previewButton = "bs_lk";
export var aiChat__previewButtonWrapper = "bs_lj";
export var aiChat__previewContainer = "bs_lb";
export var aiChat__previewDescr = "bs_lh";
export var aiChat__previewFixedBottom = "bs_ll";
export var aiChat__previewLogo = "bs_lf";
export var aiChat__previewLogoWrapper = "bs_lc";
export var aiChat__previewTitle = "bs_lg";
export var pulseLightRed = "bs_ld";