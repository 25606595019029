// extracted by mini-css-extract-plugin
export var aiChat__aiPowered = "br_k8";
export var aiChat__animate = "br_kZ";
export var aiChat__animate_content = "br_k0";
export var aiChat__answer = "br_k5";
export var aiChat__content = "br_k2";
export var aiChat__icon = "br_k1";
export var aiChat__loadingState = "br_k3";
export var aiChat__message = "br_kY";
export var aiChat__question = "br_k4";
export var aiChat__sender = "br_k6";
export var aiChat__senderInfo = "br_k7";
export var pulseSize = "br_kV";