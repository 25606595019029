import { PropsWithChildren, useEffect } from 'react'

import Header from '~/components/layouts/Header'

import * as containerStyles from './ChatLayout.module.scss'

import './ChatLayout.scss'

const ChatLayout = ({ children }: PropsWithChildren) => {
  useEffect(() => {
    const isAIChatPage = window.location.pathname.includes('/ai-chat/')

    if (isAIChatPage) {
      document.body.classList.add('aiChatBody')
      localStorage.removeItem('chatBotLoaded')
    } else {
      document.body.classList.remove('aiChatBody')
    }

    return () => {
      document.body.classList.remove('aiChatBody')
    }
  }, [])

  return (
    <>
      <div className={containerStyles.headerFixed}>
        <Header />
      </div>

      {/* overflow need for sticky flow */}
      <main className={containerStyles.chatMain}>{children}</main>
    </>
  )
}

export default ChatLayout
